// file: apps/dashboard/src/components/editForm/components/formAddressFields.jsx
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useEffect } from 'react';

import { formatFieldName } from '@patheducation/login/src/components/registrationForm/helpers';
import FormTextField from '../formTextField';
import FormAutocomplete from '../formAutocomplete';
import FormDatePicker from '../formDatePicker';
import useStyles from './styles';
import dataLists from '../../../utils/dataLists';
import MuiPhone from '../../Phone/MuiPhone';

const { countryCodeOptions, countryOptions } = dataLists;

const FormAddressFields = ({
  control, getValues, setValue, userType,
}) => {
  const { classes } = useStyles();

  useEffect(() => {
    // Set default phone number prefix if not set
    if (!getValues('phoneNumberPrefix')) {
      setValue('phoneNumberPrefix', {
        id: 'UK',
        value: '+44',
        name: 'United Kingdom',
      });
    }
  }, [getValues, setValue]);

  // Extract current prefix and number values correctly
  const currentPrefixObj = getValues('phoneNumberPrefix');
  const currentPrefix = (currentPrefixObj && currentPrefixObj.value) || '';
  const currentNumber = getValues('phoneNumber') || '';
  // Determine default country for PhoneInput based on stored prefix object
  const defaultCountry = currentPrefixObj && currentPrefixObj.id
    ? currentPrefixObj.id.toLowerCase()
    : 'gb';

  return (
    <Grid container columnSpacing={1}>
      <Grid item xs={12}>
        <FormTextField
          name="addressLine1"
          control={control}
          inputProps={{
            label: 'Address Line 1',
            autoFocus: true,
            margin: 'dense',
            required: true,
          }}
        />
        <FormTextField
          name="addressLine2"
          control={control}
          inputProps={{
            label: 'Address Line 2',
            margin: 'dense',
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="city"
          control={control}
          inputProps={{
            label: 'City',
            margin: 'dense',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="postCode"
          control={control}
          inputProps={{
            label: 'Post Code',
            margin: 'dense',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPhone
          country={defaultCountry}
          value={`+${currentPrefix}${currentNumber}`}
          containerStyle={{ marginBottom: '5px', marginTop: '10px', color: 'grey' }}
          inputStyle={{ marginBottom: '5px', marginTop: '10px' }}
          onChange={(data) => {
            const dialCode = `+${data.country.dialCode}`;
            // Determine correct country based on library provided country code
            let matchedOption = null;
            if (data.country.iso2) {
              matchedOption = countryCodeOptions.find(
                (option) => option.id.toLowerCase() === data.country.iso2.toLowerCase(),
              );
            }
            // Fallback if country match not found using id, try matching dial code
            if (!matchedOption) {
              matchedOption = countryCodeOptions.find(
                (option) => option.value === dialCode,
              );
            }
            if (matchedOption) {
              setValue('phoneNumberPrefix', matchedOption);
            } else {
              setValue('phoneNumberPrefix', { name: '', value: dialCode, id: '' });
            }
            // Remove prefix from value
            let numberWithoutPrefix = data.phone.replace(dialCode, '');
            // Remove any leading zeros if necessary
            numberWithoutPrefix = numberWithoutPrefix.replace(/^0+/, '');
            setValue('phoneNumber', numberWithoutPrefix);
          }}
          inputProps={{
            name: 'phone',
            required: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormAutocomplete
          name="countryName"
          control={control}
          options={countryOptions}
          getOptionLabel={(option) => option.value}
          label="Country"
          defaultValue={getValues('countryName')}
          inputProps={{
            label: 'Please Choose',
            required: true,
          }}
        />
      </Grid>
      {userType === 'tutor' && (
        <Grid item xs={12} sm={6} className={classes.date}>
          <FormDatePicker
            name="dateOfBirth"
            control={control}
            inputProps={{
              label: 'Date of Birth',
              disableFuture: true,
            }}
            required
          />
        </Grid>
      )}
    </Grid>
  );
};

FormAddressFields.propTypes = {
  control: PropTypes.shape({}).isRequired,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
};

export default FormAddressFields;
